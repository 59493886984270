import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "utils/AuthContext";
import "./global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toggle/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import { CategoryProvider } from "utils/DataContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
    <CategoryProvider>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </CategoryProvider>
    </AuthProvider>
  </BrowserRouter>
);

import { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ForgotPasswordService } from 'services/users';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setError('Voer een geldig e-mailadres in');
            setLoading(false);
            return;
        }

        try {
            const response = await ForgotPasswordService({ email });
            const user = response?.data?.data;

            console.log("Response:", user);

            Swal.fire({
                title: 'Succes!',
                text: 'OTP verzonden naar uw opgegeven e-mailadres!',
                icon: 'success',
                confirmButtonText: 'Oké',
            });

            localStorage.setItem("email", email);
            navigate('/otp', { state: { email } });
        } catch (err) {
            console.error('Fout tijdens verzoek:', err);
            
            setError(err?.response?.data?.error || 'Er is een fout opgetreden, probeer het later opnieuw.');

            Swal.fire({
                title: 'Fout',
                text: err?.response?.data?.error || 'Er is een fout opgetreden, probeer het later opnieuw.',
                icon: 'error',
                confirmButtonText: 'Oké',
            });
        } finally {
            setLoading(false); 
        }
    };

    return (
        <div style={{ background: "var(--color1)" }} className='d-flex justify-content-center align-items-center min-vh-100'>
            <Row className='w-100 justify-content-center'>
                <Col lg={4} md={6} sm={8} xs={12}>
                    <div className='bg-white rounded-4 shadow p-xl-5 p-sm-4 p-3'>
                        <h2 className='text-center mb-4'>Wachtwoord Vergeten</h2>

                        {error && <Alert variant="danger">{error}</Alert>}

                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail" className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="Voer uw e-mailadres in"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                            </Form.Group>

                            <Button
                                type="submit"
                                className='w-100 py-2'
                                style={{ background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }}
                                disabled={loading}
                            >
                                {loading ? 'Indienen...' : 'Indienen'}
                            </Button>
                        </Form>

                        <div className="text-center text-secondary mt-3">
                            <Link to="/login" className='text-decoration-none text-custom px-1'>
                                Terug naar Inloggen
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ForgotPassword;

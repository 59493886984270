import React from 'react';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'; // Search icon
import { useNavigate } from 'react-router-dom';

const SearchMembers = ({
    handleKeyDown,
    searchValue,
    handleInputChange,
    loadingButton,
    error,
}) => {
    const navigate = useNavigate()
    return (
        <Row className="py-5">
            <Col xs={12}>
                <Form className="d-md-flex gap-3 align-items-center">
                    <Col xs={12} md={6}>
                        <InputGroup className='w-75 '>
                            <InputGroup.Text className="bg-white border-end-0 rounded-start-3 " style={{ borderColor: 'var(--color1)' }}>
                                <FaSearch style={{ color: 'var(--color1)' }} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                className="rounded-end-3 border-start-0"
                                style={{ borderColor: 'var(--color1)' }}
                                placeholder="Search"
                                onKeyDown={handleKeyDown}
                                value={searchValue}
                                onChange={handleInputChange}    
                            />
                        </InputGroup></Col>
                    <Col className='d-md-flex justify-content-end mt-md-0 mt-2' xs={12} md={6}>
                        <Button
                            onClick={() => navigate('/overall/add-member')}
                            className="rounded-3 border-0 btn-custom"
                            disabled={loadingButton}
                        >
                            <span className="mb-0 fw-bold pe-1">&#43;</span>
                            <span className="mb-0 fs-12">New Member</span>
                        </Button></Col>
                </Form>
                {error && <p className="text-danger mt-3">{error}</p>}
            </Col>
        </Row >
    );
};

export default SearchMembers;

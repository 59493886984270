import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Swal from "sweetalert2"; // Import Swal for alerts
import {
  addNewEntry,
  deleteEntry,
  createSubcategory,
  deleteSubcategory,
  editsubCategory,
  uploadPdf,
} from "services/Analytics";
import deleteIcon from "../../assets/svg/delete.svg";
import addIcon from "../../assets/add-btn.png";
import { Dropdown, Form, Image } from "react-bootstrap";
import { FaEdit, FaEye, FaInfoCircle, FaPlusCircle, FaRegEdit, FaTrash } from "react-icons/fa";

const DataTable = ({
  showSubcategoryButton = true,
  apiData,
  UpdateTableData,
  GetData,
  category,
}) => {
  const [rowData, setRowData] = useState([]);
  const [editedRows, setEditedRows] = useState({}); // To track edited rows
  const userRole = JSON.parse(localStorage.getItem("user")) || { role: "user" };
  const [gridApi, setGridApi] = useState(null);
  const [zorgactiviteitMinWidth, setZorgactiviteitMinWidth] = useState(300);
  const [visibleColumns, setVisibleColumns] = useState([
    "dbc",
    "zorgactiviteit",
    "epic",
    "hix",
    "passantenprijs",
    "normtijdNvpc",
    "machtiging",
    "overnachting",
  ]);

  useEffect(() => {
    const updateZorgactiviteitMinWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 576) {
        setZorgactiviteitMinWidth(150);
      } else {
        setZorgactiviteitMinWidth(530);
      }
    };
  
    updateZorgactiviteitMinWidth();
  
    window.addEventListener("resize", updateZorgactiviteitMinWidth);
    window.addEventListener("orientationchange", updateZorgactiviteitMinWidth);
  
    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("resize", updateZorgactiviteitMinWidth);
      window.removeEventListener("orientationchange", updateZorgactiviteitMinWidth);
    };
  }, []);
  

  const onGridReady = (params) => {
    setGridApi(params.api); // Save grid API reference
  };

  useEffect(() => {
    if (apiData && apiData?.length > 0) {
      const transformedData = [];

      apiData?.forEach((subcategory) => {
        // Push the subcategory as a row and display it in the 'zorgactiviteit' field
        transformedData.push({
          _id: subcategory.subcategory._id, // Using subcategory as ID
          zorgactiviteit: subcategory.subcategory.name, // Display the subcategory text here
          dbc: "",
          epic: "",
          hix: "",
          passantenprijs: "",
          normtijdNvpc: "",
          machtiging: "",
          overnachting: "",
          isCategory: true,
        });

        subcategory.entries.forEach((entry) => {
          transformedData.push({
            _id: entry._id,
            zorgactiviteit: entry.zorgactiviteit,
            dbc: entry.dbc,
            epic: entry.epic,
            hix: entry.hix,
            passantenprijs: entry.passantenprijs,
            normtijdNvpc: entry.normtijdNvpc,
            machtiging: entry.machtiging,
            overnachting: entry.overnachting,
            note: entry.note || "", // Ensure note is included
            isCategory: false,
          });
        });

        // Add the 'Add Entry' button row after each subcategory's entries
        transformedData.push({
          _id: `add-button-${subcategory.subcategory.name}`,
          zorgactiviteit: "",
          dbc: "",
          epic: "",
          hix: "",
          passantenprijs: "",
          normtijdNvpc: "",
          machtiging: "",
          overnachting: "",
          isCategory: false,
          isAddButton: true, // Mark this row as the Add Button row
          subcategory: subcategory.subcategory.name, // We need the subcategory info here
          category: subcategory.category, // Assuming you have category info
        });
      });

      setRowData(transformedData);
    }
  }, [apiData]);

  const onCellValueChanged = (params) => {
    const updatedRow = { ...params.data };
    setEditedRows((prev) => ({ ...prev, [updatedRow._id]: updatedRow }));
  };

  const onCellEditingStarted = (params) => {
    if (
      params.data.isCategory ||
      params.data.isAddButton ||
      params.data.isAddSubcategoryButton
    ) {
      params.api.stopEditing();
    }
  };

  const handleDeleteSubcategory = async (subcategoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Deleting this subcategory will also delete all its associated entries. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteSubcategory(subcategoryId); // Call the delete subcategory API
          if (response?.data) {
            GetData(); // Refresh the data
            Swal.fire(
              "Deleted!",
              "The subcategory and its entries have been deleted.",
              "success"
            );
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Delete Failed",
            text: "There was an error deleting the subcategory.",
          });
        }
      }
    });
  };

  const handleEditSubcategory = async (subcategoryId, subcategoryName) => {
    Swal.fire({
      title: "Edit Subcategory",
      text: "Enter the new subcategory name here:",
      input: "text",
      inputPlaceholder: "Enter new subcategory name",
      inputValue: subcategoryName, // Pre-filling the input with the current subcategory name
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Update",
      preConfirm: async (newSubcategoryName) => {
        if (!newSubcategoryName) {
          Swal.showValidationMessage("Please enter a valid subcategory name.");
          return;
        }

        try {
          const response = await editsubCategory(subcategoryId, {
            name: newSubcategoryName,
          }); // Call the edit subcategory API
          if (response?.data) {
            Swal.fire(
              "Success!",
              "Subcategory updated successfully.",
              "success"
            );
            GetData(); // Refresh the data
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Update Failed",
            text: "There was an error updating the subcategory.",
          });
        }
      },
    });
  };

  const customActionRenderer = (params) => {
    if (
      params.data.isCategory &&
      userRole.role === "admin" &&
      showSubcategoryButton
    ) {
      return (
        <div className="d-flex gap-1">
          <button
            onClick={() =>
              handleEditSubcategory(params.data._id, params.data.zorgactiviteit)
            }
            className="btn btn-transparent bg-transparent border-0 btn-sm"
          >
            <FaEdit style={{ color: "var(--color1)", fontSize: "15px" }} />
          </button>
          <button
            onClick={() => handleDeleteSubcategory(params.data._id)}
            className="btn btn-transparent bg-transparent border-0 btn-sm"
          >
            <FaTrash style={{ color: "var(--color1)", fontSize: "15px" }} />
          </button>
        </div>
      );
    }

    if (
      !params.data.isCategory &&
      !params.data.isAddButton &&
      !params.data.isAddSubcategoryButton &&
      userRole.role === "admin"
    ) {
      return (
        <div className="d-flex gap-1">
          <button
            onClick={() => handleAddNote(params.data._id, params.data.note)}
            className="btn btn-transparent  bg-transparent border-0 btn-sm "
          >
            <FaInfoCircle style={{ fontSize: "15px", color: "#4d568e" }} />
          </button>
          <button
            onClick={() => handleDelete(params.data._id)}
            className="btn btn-transparent  bg-transparent border-0 btn-sm "
          >
            <Image
              src={deleteIcon}
              width={20}
              height={20}
              style={{ fill: "white" }}
            />
          </button>
        </div>
      );
    }
    return null;
  };

  const handleAddRecord = async (subcategory) => {
    Swal.fire({
      title: "Add New Entry",
      text: `You are adding a new entry to the subcategory: ${subcategory.name}.`,
      icon: "info",
      input: "text", // Ask only for the DBC value
      inputPlaceholder: "Enter a DBC",
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {
        if (!value) {
          Swal.showValidationMessage("Please enter a valid DBC.");
          return;
        }

        try {
          const data = {
            dbc: value,
            subcategory,
            category: category._id,
          };
          const response = await addNewEntry(data);

          if (response?.data) {
            Swal.fire("Success", "New entry added successfully.", "success");
            GetData(); // Refresh the table data
          }
        } catch (error) {
          console.log(error);
          Swal.fire("Error", "Failed to add new entry.", "error");
        }
      },
    });
  };

  const handleAddSubcategory = async () => {
    Swal.fire({
      title: "Add New Subcategory",
      text: "Please enter the subcategory name:",
      input: "text",
      inputPlaceholder: "Enter subcategory name",
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,
      preConfirm: async (subcategoryName) => {
        if (!subcategoryName) {
          Swal.showValidationMessage("Please enter a valid subcategory name.");
          return;
        }

        try {
          const data = { name: subcategoryName };
          const response = await createSubcategory(category._id, data); // Call your API here

          if (response?.data) {
            Swal.fire(
              "Success",
              "New subcategory added successfully.",
              "success"
            );
            GetData(); // Refresh the table data
          }
        } catch (error) {
          Swal.fire("Error", "Failed to add new subcategory.", "error");
        }
      },
    });
  };

  const handleDelete = async (id) => {
    if (userRole.role !== "admin") {
      Swal.fire({
        icon: "error",
        title: "Access Denied",
        text: "You do not have permission to delete this row.",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteEntry(id);

          if (response?.data) {
            GetData();
            Swal.fire("Deleted!", "Your entry has been deleted.", "success");
          }
        } catch (error) {
          console.error("Error deleting data:", error);
          Swal.fire({
            icon: "error",
            title: "Delete Failed",
            text: "There was an error deleting the entry.",
          });
        }
      }
    });
  };

  const handleSaveChanges = async () => {
    if (userRole.role !== "admin") {
      Swal.fire({
        icon: "error",
        title: "Access Denied",
        text: "You do not have permission to save changes.",
      });
      return;
    }

    const updates = Object.values(editedRows); // Get all the updated rows

    const dataToSend = {
      entries: updates,
    };

    try {
      await UpdateTableData(dataToSend);

      GetData();
      Swal.fire({
        title: "Success",
        text: "Updated Successfully",
        icon: "success",
        confirmButtonText: "OK",
        timer: 1000,
        timerProgressBar: true,
      });
      setEditedRows({});
    } catch (error) {
      console.error("Error updating data:", error);
      Swal.fire({
        icon: "error",
        title: "Update Failed",
        text: "There was an error updating the data.",
      });
    }
  };

  const handleAddNote = async (entryId, note) => {
    let charCount = note ? note.length : 0;
    Swal.fire({
      title: "Add a Note",
      input: "textarea",
      inputValue: note, // Pre-fill the textarea with the existing note
      inputPlaceholder: "Enter your note here...",
      text: "Maximum 350 characters",
      showCancelButton: true,
      confirmButtonText: "Save Note",
      html: `<div style="text-align: left;">
               <p id="char-counter" style="margin-top: 5px; font-size: 0.85em; color: #888;">
                 ${charCount}/350 characters
               </p>
             </div>`,
      preConfirm: async (newNote) => {
        if (!newNote) {
          Swal.showValidationMessage("Please enter a valid note.");
          return;
        }

        try {
          const dataToSend = {
            entries: [
              {
                _id: entryId,
                note: newNote,
              },
            ],
          };

          await UpdateTableData(dataToSend);
          GetData();
          Swal.fire("Success", "Note added successfully.", "success");
        } catch (error) {
          console.error("Error adding note:", error);
          Swal.fire("Error", "Failed to add note.", "error");
        }
      },
      didOpen: () => {
        const textarea = Swal.getInput();
        const charCounter = document.getElementById("char-counter");

        textarea.setAttribute("maxlength", "350");

        textarea.addEventListener("input", () => {
          const textLength = textarea.value.length;
          charCounter.textContent = `${textLength}/350 characters`;
        });
      },
    });
  };

  const handleClearMachtiging = async (entryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete the PDF file. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const dataToSend = {
            entries: [
              {
                _id: entryId,
                machtiging: "", // Empty the machtiging field
              },
            ],
          };
  
          await UpdateTableData(dataToSend);
  
          GetData();
  
          Swal.fire("Deleted!", "Machtiging cleared successfully.", "success");
        } catch (error) {
          console.error("Error clearing machtiging:", error);
          Swal.fire("Error", "Failed to clear machtiging.", "error");
        }
      }
    });
  };
  

  const customCellRenderer = (params) => {
    if (
      params.colDef.field === "machtiging" &&
      !params.data.isAddButton &&
      !params.data.isCategory
    ) {
      if (userRole.role === "admin") {
        return (
          <div className="d-flex flex-row ">
            <button
              className={`btn pb-2 mt-0 pt-0 px-2 ${!params.value && 'w-100'}`}
              onClick={() => handleUploadPdf(params.data._id)}
            >
              {
                params.value?(
                  <FaRegEdit
                  style={{ color: "var(--color1)", fontSize: "15px" }}
                />
                ):(
                  <FaPlusCircle
                  style={{ color: "var(--color1)", fontSize: "15px" }}
                />
                )
              }
             
            </button>
           {
            params.value && (
              <>
                <button
              className="btn pb-2 mt-0 pt-0 px-2"
              onClick={() => handleClearMachtiging(params.data._id)}
            >
                  <FaTrash
                  style={{ color: "var(--color1)", fontSize: "15px" }}
                />
            </button>
                 <button
              className="btn pb-2 mt-0 pt-0 px-2 "
              onClick={() => handleDownloadPdf(params.value)}
            >
                  <FaEye
                  style={{ color: "var(--color1)", fontSize: "15px" }}
                />
            </button>
          
              </>
           
            
            )
           }
          </div>
        );
      } else if (params.value) {
        return (
          <button
            className="btn pb-2 mt-0 pt-0 w-100"
            onClick={() => handleDownloadPdf(params.value)}
          >
            <FaPlusCircle
              style={{ color: "var(--color1)", fontSize: "15px" }}
            />
          </button>
        );
      }
      return null;
    }
    if (
      params.data.isAddButton &&
      userRole.role === "admin" &&
      params.colDef.field !== "machtiging"
    ) {
      return (
        <button
          className="btn btn-custom text-white m-1 d-flex align-items-center justify-content-center gap-2"
          onClick={() => handleAddRecord(params.data.subcategory)}
        >
          <Image className="" width={20} src={addIcon} />
        </button>
      );
    }
    return params.value;
  };

  // Column definitions with editable fields
  const columns = [
    {
      field: "dbc",
      headerName: "DBC",
      width: 80,
      cellRenderer: customCellRenderer,
      tooltipField: "note",
      tooltipComponentParams: { color: "#4d568e" },
    },
    {
      field: "zorgactiviteit",
      headerName: "Zorgactiviteit",
      flex: 1,
      minWidth: zorgactiviteitMinWidth,
    },
    { field: "epic", headerName: "Epic", width: 80 },
    { field: "hix", headerName: "Hix", width: 80 },
    { field: "passantenprijs", headerName: "€", width: 80 },
    {
      field: "normtijdNvpc",
      headerName: "Normtijd NVPC",
      width: 120,
    },
    {
      field: "machtiging",
      headerName: "Machtiging",
      width: 120,
      minWidth: 100,
      cellRenderer: customCellRenderer,
      editable: false,
    },
    {
      field: "overnachting",
      headerName: "Overnachting",
      width: 120,
    },
    ...(userRole.role === "admin"
      ? [
          {
            field: "actions",
            headerName: "Actions",
            width: 120,
            cellRenderer: customActionRenderer,
          },
        ]
      : []),
  ];

  const handleColumnToggle = (colId) => {
    const isVisible = visibleColumns.includes(colId);
    gridApi.setColumnVisible(colId, !isVisible); // Toggle column visibility
    setVisibleColumns((prev) =>
      isVisible ? prev.filter((col) => col !== colId) : [...prev, colId]
    );
  };

  const handleCellClick = (params) => {
    const allowedColumns = ["epic", "hix"]; // Define columns where copy is allowed

    if (
      userRole.role !== "admin" &&
      allowedColumns.includes(params.colDef.field) && // Check if the column is 'epic' or 'hix'
      !params.data.isAddButton &&
      !params.data.isCategory &&
      !params.data.isAddSubcategoryButton
    ) {
      const cellValue = params.value;
      if (cellValue) {
        navigator.clipboard
          .writeText(cellValue)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Copied!",
              text: "Cell content copied to clipboard.",
              timer: 1000,
              showConfirmButton: false,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Copy Failed",
              text: "There was an issue copying the content.",
            });
          });
      }
    }
  };

  const handleUploadPdf = async (entryId) => {
    const { value: file } = await Swal.fire({
      title: "Upload PDF",
      input: "file",
      inputAttributes: {
        accept: "application/pdf",
        "aria-label": "Upload your PDF file",
      },
      showCancelButton: true,
      confirmButtonText: "Upload",
      showLoaderOnConfirm: true,
      preConfirm: async (file) => {
        if (!file) {
          Swal.showValidationMessage("Please select a PDF file.");
          return;
        }

        const formData = new FormData();
        formData.append("attachment", file);

        try {
          const response = await uploadPdf(formData);

          // const result = await response.json();
          if (response.data) {
            const pdfUrl = response.data.data;

            // Now update the machtiging field for the entry
            const dataToSend = {
              entries: [
                {
                  _id: entryId,
                  machtiging: pdfUrl,
                },
              ],
            };

            await UpdateTableData(dataToSend);
            GetData(); // Refresh the table data
            Swal.fire("Success", "PDF uploaded successfully.", "success");
          } else {
            Swal.fire("Error", "Failed to upload PDF.", "error");
          }
        } catch (error) {
          Swal.fire("Error", "Error during file upload.", "error");
        }
      },
    });
  };

  const handleDownloadPdf = (pdfUrl) => {
    const newTab = window.open(pdfUrl, '_blank');
    if (!newTab) {
      // In case pop-ups are blocked
      Swal.fire({
        icon: 'error',
        title: 'Unable to open PDF',
        text: 'Please allow pop-ups for this site.',
      });
    }
  };
  

  return (
    <div>
      <div>
        <Dropdown className="mx-2 mb-3">
          <Dropdown.Toggle
            className="btn-custom px-2 py-1"
            variant="primary"
            id="dropdown-basic"
          >
            Columns
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {columns
              .filter((col) => col.field !== "actions") // Filter out the "actions" column
              .map((col) => (
                <Dropdown.Item
                  as="div"
                  key={col.field}
                  onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing when clicking on checkboxes
                >
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${col.field}`}
                    label={col.headerName} // Make label clickable
                    checked={visibleColumns.includes(col.field)}
                    onChange={() => handleColumnToggle(col.field)}
                  />
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columns}
          tooltipShowDelay={300}
          domLayout="autoHeight"
          onGridReady={onGridReady}
          stopEditingWhenCellsLoseFocus={true}
          onCellClicked={handleCellClick}
          // enableCellTextSelection = {userRole?.role==="admin"?true:false}
          suppressRowHoverHighlight={true}
          rowClassRules={{
            "subcategory-row": (params) => params.data.isCategory,
            // Apply this class to subcategory rows
          }}
          getRowHeight={(params) => {
            if (params.data.isCategory) {
              return 40; // Height for category rows
            } else if (params.data.isAddButton) {
              return 50; // Height for "Add Entry" and "Add Subcategory" button rows
            }
            return 30; // Default row height
          }} // Adjust height for category rows
          defaultColDef={{
            resizable: true,
            sortable: false,
            editable: userRole?.role === "admin" ? true : false,
            tooltipComponentParams: {
              color: "#4d568e",
              backgroundColor: "black",
            },
          }}
          onCellValueChanged={onCellValueChanged} // Capture cell edits
          onCellEditingStarted={onCellEditingStarted} // Prevent editing of subcategory rows
        />
      </div>
      {userRole.role === "admin" && (
        <div className="d-flex gap-2 justify-content-between align-items-center">
          {showSubcategoryButton && (
            <button
              className="btn btn-custom text-white m-2 d-flex align-items-center justify-content-center gap-2"
              onClick={handleAddSubcategory}
            >
              <Image className="" width={20} src={addIcon} />

              <p className="m-0">Add Subcategory</p>
            </button>
          )}
          <button
            className="btn btn-custom text-white my-1"
            onClick={handleSaveChanges}
            disabled={Object.keys(editedRows).length === 0}
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default DataTable;

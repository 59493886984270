import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaHome } from "react-icons/fa";
import { GetSideBar } from 'services/Analytics';
import picture2 from 'assets/icons/Picture2.png';
import picture3 from 'assets/icons/Picture3.png';
import picture4 from 'assets/icons/Picture4.png';
import picture5 from 'assets/icons/Picture5.png';
import picture6 from 'assets/icons/Picture6.png';
import picture7 from 'assets/icons/Picture7.png';
import picture8 from 'assets/icons/Picture8.png';
import picture9 from 'assets/icons/Picture9.png';
import picture10 from 'assets/icons/Picture10.png';

const pictures = [
    picture2,
    picture3,
    picture4,
    picture5,
    picture6,
    picture7,
    picture8,
    picture9,
    picture10,
];

const useSidebarTabs = () => {
    const [menus, setMenus] = useState([]);
    const userRole = JSON.parse(localStorage.getItem("user"));

    const getResponse = async () => {
        const response = await GetSideBar();
        if (response?.data) {
            const { data } = response.data;
            setMenus(data);
        }
    };

    useEffect(() => {
        getResponse();
    }, []);

    const SidebarTabs1 = {
        label: "Alles",
        icon: <FaHome />,
        link: "/dashboard?active=Alles",
        labelList:
            userRole?.role === "admin"
                ? [
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Add Member", link: "/overall/add-member" },
                    { label: "All Member", link: "/overall/all-member" },
                ]
                : [],
    };

    const SidebarTabs = [
        SidebarTabs1,
        ...menus.map((p, index) => ({
            label: p?.category,
            icon: <Image src={pictures[index]} alt="image" width={20} />,
            link: `/dashboard?active=${p?.category}`,
            labelList: p?.subcategories?.map((sub) => ({
                label: sub,
                link: `/${sub.replace(/ /g, '-').toLowerCase()}`,
            })),
        }))
    ];

    return SidebarTabs;
};

export default useSidebarTabs;

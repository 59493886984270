import { GetApiData, PostFormData } from "utils/http-client";
export const GetDashboardData = function () {
  return GetApiData(`/entries`, "GET", null, true);
};
export const GetSideBar = function () {
  return GetApiData(`/entries/get-all-names`, "GET", null, true);
};

export const UsersGet = function () {
  return GetApiData(`/user`, "GET", null, true);
};


export const UserSearch = function (value) {
  return GetApiData(`/user?key=${value}`, "GET", null, true);
};
export const fetchTableData = function (category, subcategory) {
  const encodedCategory = encodeURIComponent(category);
  const encodedSubcategory = encodeURIComponent(subcategory);
  return GetApiData(`/entries/get-subcategories-detail?category=${encodedCategory}&subcategory=${encodedSubcategory}`, "GET", null, true);
};

export const UpdateTableData = function (data) {
  return GetApiData(`/entries`, "PUT",data , true);
};

export const deleteEntry = function (id) {
  return GetApiData(`/entries/${id}`, "DELETE",null , true);
};

export const addNewEntry = function (data) {
  return GetApiData(`/entries`, "POST",data , true);
};


export const createSubcategory = function (category,data) {
  return GetApiData(`/entries/subcategory/${category}`, "POST",data , true);
};

export const deleteSubcategory = function (category) {
  return GetApiData(`/entries/subcategory/${category}`, "DELETE",null , true);
};


export const editsubCategory = function (category,data) {
  return GetApiData(`/entries/subcategory/${category}`, "PUT",data , true);
};

export const uploadPdf = function (data) {
  return PostFormData(`/upload/pdf`, "POST",data , true);
};


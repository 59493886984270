import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useCategory } from "utils/DataContext";

const SidebarDropdown = ({ data, isOpen, handleToggle, SideClose, handleCloseSidebar }) => {
    const { updateCategory } = useCategory();
    const handleCategoryChange = (newCategory) => {
        updateCategory(newCategory);
        handleCloseSidebar()
    };

    return (

        <div className="mb-1">
            <NavLink
                to={data?.link}
                onClick={handleToggle}
                className={`py-2 pe-4 mb-2 d-flex small align-items-center text-decoration-none rounded-3 sidebar-link`}
                style={({ isActive }) => ({
                    color: 'var(--color1)',
                    paddingLeft: "12px",
                    fontWeight: isActive ? 'bold' : '',
                    background: isActive ? 'var(--color2)' : 'transparent',
                    borderRadius: isOpen ? '0 8px 8px 0' : '',
                    transition: 'all 0.3s ease-in-out',
                })}
            >
                <div className="sidebar-icon">
                    {data?.icon}
                </div>
                <p className={`mb-0 d-flex justify-content-between w-100 ${isOpen ? "inner-link" : ""}`}>
                    {data?.label}
                    <span className="ms-auto">
                        {isOpen ? <FaCaretDown /> : <FaCaretRight />}
                    </span>
                </p>
            </NavLink>
            {isOpen && SideClose && (
                <div className="dropdown-items mt-2 ms-5" style={{ borderLeft: "2px solid #2422208F" }}>
                    {data?.labelList?.map((linkItem, index) => (
                        <NavLink
                            key={index}
                            onClick={() => handleCategoryChange(linkItem?.label)}
                            to={linkItem?.link + `?category=${data?.label}`}
                            style={({ isActive }) => ({
                                color: "#2422208F",
                                fontWeight: isActive ? 'bold' : '',
                                background: isActive ? 'var(--color2)' : 'transparent',
                                borderRadius: '0 8px 8px 0',
                                transition: 'all 0.3s ease',
                            })}
                            className={`d-block py-1 px-3 small text-decoration-none`}
                        >
                            {linkItem?.label}
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SidebarDropdown;

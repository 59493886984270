import UserCard from 'components/shared/UserCard';
import { useState, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { UserSearch, GetAllUser, UserDelete, UserStatus } from 'services/users';
import UserCardSkeleton from 'components/loaders/UserCardSkeleton';
import SearchMembers from './SearchMembers';

const Members = () => {
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [membersData, setMembersData] = useState([]);

    // Fetch all members initially
    useEffect(() => {
        fetchMembers();
    }, []);

    // Fetch members (all or filtered)
    const fetchMembers = async (searchQuery = '') => {
        setLoading(true);
        try {
            const response =  await GetAllUser(searchQuery);
            if (response?.data) {
                setMembersData(response?.data?.data);
            } else {
                setMembersData([]);
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to load members data.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle search when pressing enter
    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            fetchMembers(searchValue);  // Fetch members based on search query
        }
    };

    // Handle input changes in the search box
    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleToggleChange = async (id, isActive) => {
        setLoadingButton(true);
        const status = isActive === "active" ? "inactive" : "active";
        try {
            const response = await UserStatus(id, status);
            if (response) {
                Swal.fire({
                    title: 'Success!',
                    text: response.message,
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                fetchMembers(); // Refresh members after toggling status
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: response.message || 'Failed to update member status.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to toggle member status.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        } finally {
            setLoadingButton(false);
        }
    };

    const handleDelete = async (id) => {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: "This will permanently delete the member.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        });

        if (confirmed.isConfirmed) {
            setLoadingButton(true);
            try {
                const response = await UserDelete(id);
                if (response) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });
                    fetchMembers(); // Refresh members after deletion
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.message || 'Failed to delete member.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to delete member.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                setLoadingButton(false);
            }
        }
    };

    return (
        <Card className="shadow-sm border-0 p-md-4 p-1 rounded-4">
            <Container fluid>
                <h1>Members</h1>
                <SearchMembers handleInputChange={handleInputChange} handleKeyDown={handleKeyDown} />
                <Row>
                    {loading ? (
                        Array.from({ length: 4 }).map((_, index) => (
                            <Col xs={12} lg={3} key={index}>
                                <UserCardSkeleton />
                            </Col>
                        ))
                    ) : membersData.length === 0 ? (
                        <Col xs={12}>
                            <div className="text-center my-5">
                                <h5>No Results Found</h5>
                            </div>
                        </Col>
                    ) : (
                        membersData.map((mem) => (
                            <Col key={mem?._id} xxl={3} xl={3} lg={4} md={6} xs={12}>
                                <UserCard
                                    loadingButton={loadingButton}
                                    id={mem?._id}
                                    Name={mem?.fullName}
                                    CreatedAt={mem?.createdAt}
                                    image={mem?.image}
                                    isActive={mem?.status === "active" ? true : false}
                                    Email={mem?.email}
                                    Phone={mem?.phone}
                                    designation={""}
                                    handleToggleChange={() => handleToggleChange(mem?._id, mem?.status)}
                                    handleDelete={() => handleDelete(mem?._id)}
                                />
                            </Col>
                        ))
                    )}
                </Row>
            </Container>
        </Card>
    );
};

export default Members;

import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import SidebarHeader from './SidebarHeader';
import OffCanvas from './OffCanvas';
import { useState } from 'react';


const DashboardLayout = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const handleShowSidebar = () => setShowSidebar(true);
    const handleCloseSidebar = () => setShowSidebar(false);
    const [isActive, setIsActive] = useState(false);
    return (

        <div className='d-flex flex-column '>
            <div className='d-lg-flex' >
                <div className={`d-lg-block d-none min-vh-100   ${isActive ? 'main-sidebar' : "main-sidebar-close"}`}>
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>
                <div className={`d-flex flex-column ${isActive ? 'main-component' : "main-component-close"}`} style={{ flex: 1 }}>
                    <div className='px-3 py-md-2' style={{ overflow: 'auto', height: '100vh' }}>
                        <div className='px-md-3 py-md-2'>
                            <SidebarHeader onLogoClick={handleShowSidebar} />
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
            <OffCanvas showSidebar={showSidebar} handleCloseSidebar={handleCloseSidebar} />

        </div>

    );
}

export default DashboardLayout;

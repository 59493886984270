import { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2'; 
import { ResetPasswordService } from 'services/users';

const ResetPassword = () => {
    const location = useLocation();
    const { OTP, email } = location.state || {};
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!password || !confirmPassword) {
            Swal.fire('Fout', 'Beide wachtwoordvelden zijn verplicht', 'error');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            Swal.fire('Fout', 'Wachtwoorden komen niet overeen', 'error');
            setLoading(false);
            return;
        }

        try {
            if (OTP) {
                const response = await ResetPasswordService({ email, code: OTP, password });
                console.log('Wachtwoord reset reactie:', response);
                Swal.fire('Succes', 'Uw wachtwoord is gereset!', 'success').then(() => {
                    navigate('/login');
                });
            } else {
                navigate('/otp');
            }
        } catch (err) {
            console.error('Fout tijdens het resetten van het wachtwoord:', err);
            Swal.fire('Fout', err?.response?.data?.error || 'Er is een fout opgetreden, probeer het later opnieuw.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ background: "var(--color1)" }} className=' d-flex justify-content-center align-items-center min-vh-100'>
            <Row className='w-100 justify-content-center'>
                <Col lg={4} md={6} sm={8} xs={12}>
                    <div className='bg-white rounded-4 shadow p-xl-5 p-sm-4 p-3'>
                        <h2 className='text-center mb-4'>Reset Wachtwoord</h2>

                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formNewPassword" className="mb-3 position-relative">
                                <Form.Label className="text-custom">Nieuw Wachtwoord</Form.Label>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Voer nieuw wachtwoord in"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                                <Button
                                    variant="link"
                                    className="position-absolute text-secondary end-0 top-50 me-2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </Form.Group>

                            <Form.Group controlId="formConfirmPassword" className="mb-3 position-relative">
                                <Form.Label className="text-custom">Bevestig Wachtwoord</Form.Label>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Bevestig nieuw wachtwoord"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                                <Button
                                    variant="link"
                                    className="position-absolute text-secondary end-0 top-50 me-2"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </Form.Group>

                            <Button
                                type="submit"
                                className='w-100 py-2'
                                style={{ background: 'var(--bg-ltr)', borderColor: 'var(--primary-color)' }}
                                disabled={loading}
                            >
                                {loading ? 'Bezig met resetten...' : 'Reset Wachtwoord'}
                            </Button>
                        </Form>

                        <div className="text-center text-secondary mt-3">
                            <Link to="/login" className='text-decoration-none text-custom px-1'>
                                Terug naar Inloggen
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ResetPassword;

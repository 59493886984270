import logo from "assets/logo/DOTgod.svg";
import { NeedHelp } from 'assets/svg/Svg';
import SidebarDropdown from "components/shared/SidebarDropdown";
import useSidebarTabs from "components/shared/SideBarTabs";
import { useState } from 'react';
import { Card, Image, Nav } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = ({ isActive, setIsActive }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const SidebarTabs = useSidebarTabs();

  const handleToggle = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };
  const handleCloseSidebar = () => { }
  return (
    <div
      className={`d-flex flex-column min-vh-100 px-2 sidebar ${isActive ? 'active' : ''}`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <Nav className="flex-column flex-grow-1">
        {isActive ? (
          <div className='d-flex align-items-center justify-content-center mx-auto' style={{ width: "100px", height: "100px" }}>
            <Link className="d-none d-lg-flex py-4 justify-content-center align-items-center">
              <Image src={logo} alt='Logo' className='w-100' />
            </Link>
          </div>
        ) : (
          <div className='my-auto d-flex align-items-center ps-3' style={{ width: "50px", height: "100px" }}>
            <Link className="d-none d-lg-flex ">
              <FaBars color='var(--color1)' size={20} />
            </Link>
          </div>
        )}

        {SidebarTabs?.map((menu, i) => (
          <SidebarDropdown
            SideClose={isActive}
            handleToggle={() => handleToggle(i)}
            isOpen={openDropdown === i}
            key={i}
            data={menu}
            handleCloseSidebar={handleCloseSidebar}
          />
        ))}
      </Nav>

      {/* {isActive && (
        <div className='mt-auto mb-1'>
          <Card className="sidebar-help rounded-4 text-white p-4 w-100">
            <NeedHelp />
            <p className="small mb-0 mt-3">Need Help?</p>
            <p className="fs-12">Please check our docs</p>
            <div>
              <Link to="/docs" className="btn btn-close-white bg-white text-dark w-100">
                Documentation
              </Link>
            </div>
          </Card>
        </div>
      )} */}
    </div>
  );
};

export default Sidebar;

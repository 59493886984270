// const parseJwt = (token) => {
//     try {
//         return JSON.parse(atob(token.split('.')[1]));
//     } catch (e) {
//         return null;
//     }
// };

export function AuthHeader() {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser && loggedInUser.token
    ? { "x-access-token": loggedInUser.token }
    : {};
}
export const GetUserRoles = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser?.role || [];
};
export const AuthName = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser?.fullName || "User";
};


export const AuthVerify = () => {
  const loggedInUser = JSON.parse(localStorage?.getItem("user"));
  // if (loggedInUser && loggedInUser?.token) {
  if (loggedInUser) {
    return true;
  } else return false;
};

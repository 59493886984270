import CustomTable from 'components/shared/CustomTable';
import DataTable from 'components/shared/DataTable';
import Loader from 'components/shared/Loader';
import { useEffect, useState, useCallback } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { fetchTableData, UpdateTableData } from 'services/Analytics';
import Swal from 'sweetalert2';
import { useCategory } from 'utils/DataContext';
import debounce from 'lodash.debounce'; // Import lodash debounce for debouncing the search input

const HeadingWithTable = () => {
    const { category } = useCategory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // For filtered data
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedColumn, setSelectedColumn] = useState(''); // State for selected column
    const MainCategory = queryParams.get('category');

    const columnOptions = [
        { value: '', label: 'All Columns' },
        { value: 'dbc', label: 'DBC' },
        { value: 'zorgactiviteit', label: 'Zorgactiviteit' },
        { value: 'epic', label: 'Epic' },
        { value: 'hix', label: 'Hix' },
        { value: 'passantenprijs', label: '€' },
        { value: 'normtijdNvpc', label: 'Normtijd NVPC' },
        { value: 'machtiging', label: 'Machtiging' },
        { value: 'overnachting', label: 'Overnachting' },
    ];

    const handleUpdateTableData = async (data) => {
        try {
            const response = await UpdateTableData(data);
            return response;
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: "There was an error updating the data.",
            });
        }
    };

    // Fetch data with optional search query
    const fetchData = async (query = '') => {
        try {
            setLoading(true);
            const response = await fetchTableData(MainCategory, category);
            if (response?.data) {
                setTableData(response?.data?.data);
                setFilteredData(response?.data?.data); 
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There was an error fetching the data.",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (MainCategory && category) {
            fetchData(); // Fetch data when MainCategory and category are present
        }
    }, [MainCategory, category]);

    const filterData = (query) => {
        if (!query) {
            setFilteredData(tableData); // Reset to original data if the query is empty
            return;
        }

        const lowerCaseQuery = query.toLowerCase();

        const filtered = tableData?.subcategories?.map((sub) => {
            const matchingEntries = sub.entries.filter((entry) => {
                if (selectedColumn) {
                    return entry[selectedColumn]?.toLowerCase().includes(lowerCaseQuery);
                } else {
                    return (
                        entry.dbc?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.zorgactiviteit?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.epic?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.hix?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.passantenprijs?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.normtijdNvpc?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.machtiging?.toLowerCase().includes(lowerCaseQuery) ||
                        entry.overnachting?.toLowerCase().includes(lowerCaseQuery)
                    );
                }
            });

            return matchingEntries.length > 0 ? { ...sub, entries: matchingEntries } : null;
        }).filter(Boolean);

        setFilteredData({ ...tableData, subcategories: filtered });
    };

    // Debounced search handler
    const debouncedSearch = useCallback(
        debounce((query) => {
            filterData(query);
        }, 300), // Debounce delay of 300ms
        [tableData, selectedColumn] // Add `selectedColumn` as a dependency
    );

    useEffect(() => {
        debouncedSearch(searchQuery); // Trigger debounced search on query change
    }, [searchQuery, debouncedSearch, selectedColumn]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleColumnChange = (e) => {
        setSelectedColumn(e.target.value); // Update selected column
    };

    return (
        <Container fluid>
                    <Form onSubmit={(e) => e.preventDefault()}>
                <Row className='m-2'>
                        <Col xs={12} md={3} className="  d-flex">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearch}
                                style={{ borderColor: 'var(--color1)' }}
                            />
                        </Col>
                        <Col xs={12} md={2} className="  d-flex">
                            <Form.Select onChange={handleColumnChange} value={selectedColumn} style={{ borderColor: 'var(--color1)' }}>
                                {columnOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                </Row>

                    </Form>


            <Card className="p-3 border-0 mb-4 shadow-sm rounded-4">
                <h5 className="heading">{category}</h5>
                {loading ? (
                    <Loader />
                ) : (
                    <DataTable showSubcategoryButton={false} apiData={filteredData?.subcategories} category={filteredData?.category} UpdateTableData={handleUpdateTableData} GetData={fetchData} />
                )}
            </Card>
        </Container>
    );
};

export default HeadingWithTable;

import logo from 'assets/logo/DOTgod.svg';
import { Image, Offcanvas } from 'react-bootstrap';

import SidebarDropdown from 'components/shared/SidebarDropdown';
import useSidebarTabs from 'components/shared/SideBarTabs';
import { useState } from 'react';
const OffCanvas = ({ showSidebar, handleCloseSidebar }) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const SidebarTabs = useSidebarTabs();

    const handleToggle = (index) => {
        setOpenDropdown((prev) => (prev === index ? null : index));
    };
    return (
        <Offcanvas style={{ width: "300px" }} show={showSidebar} onHide={handleCloseSidebar} placement="start">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='fw-semibold' style={{ color: "var(--primary-color)" }}>                    <Image src={logo} alt="Logo" style={{ height: '32px' }} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0'>
                {SidebarTabs?.map((menu, i) =>
                    <SidebarDropdown
                        SideClose={true}
                        data={menu}
                        key={i}
                        handleCloseSidebar={handleCloseSidebar}
                        handleToggle={() => handleToggle(i)}
                        isOpen={openDropdown === i}

                    />
                )}
            </Offcanvas.Body>
        </Offcanvas >
    )
}
export default OffCanvas
import React from 'react'
import { Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const UserCardSkeleton = () => {
    return (
        <Card
            className='text-center my-3 py-2 border-0'
            style={{
                borderRadius: "20px",
                overflow: "hidden",
                backgroundColor: "#ffffff",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
        >
            <div className='text-end me-2'>
                <Skeleton width={24} height={24} circle />
            </div>
            <div style={{ position: 'relative', width: "100px", height: "100px", margin: "auto" }}>
                <Skeleton width={"100%"} height="100%" style={{ borderRadius: "60px", overflow: "hidden" }} />
            </div>
            <Card.Body>
                <Card.Title>
                    <Skeleton width="60%" height={24} />
                </Card.Title>
                <Card.Text>
                    <Skeleton width="80%" height={20} />
                </Card.Text>
                <label>
                    <Skeleton width={40} height={20} />
                </label>
                <div className="d-flex gap-4 justify-content-center mt-1">
                    <Skeleton width={30} height={30} circle />
                    <Skeleton width={30} height={30} circle />
                </div>
            </Card.Body>
        </Card>
    )
}

export default UserCardSkeleton

import { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { UsersCreate } from 'services/users';
import Swal from 'sweetalert2';

const AddMember = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    // Update formData and reset error for the specific field being changed
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Update form data
        setFormData({ ...formData, [name]: value });

        // Reset the error for the specific field
        if (error[name]) {
            setError({ ...error, [name]: '' });
        }
    };

    const handleFormSubmit = async () => {
        let validationErrors = {};
        if (!formData.fullName) validationErrors.fullName = 'Full name is required';
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email)) {
                validationErrors.email = 'Please enter a valid email address';
            }
        }
        if (!formData.password) validationErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) validationErrors.confirmPassword = 'Passwords do not match';
        setError(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            try {
                const response = await UsersCreate(formData);
                setLoading(false);

                if (response?.data) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'User created successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to create user. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                const apiErrors = error?.response?.data?.errors || [];
                const formattedErrors = apiErrors.reduce((acc, err) => {
                    acc[err.param] = err.msg;
                    return acc;
                }, {});

                setError(formattedErrors);
                Swal.fire({
                    title: 'Error!',
                    text: 'An error occurred while creating the user. Please check your input and try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCancel = () => {
        setFormData({
            fullName: '',
            email: '',
            password: '',
            confirmPassword: ''
        });
        setError({});
        setLoading(false);
    };

    return (
        <Container fluid>
            <h5 className='heading'>Add Member</h5>
            <Form className="pt-2">
                <div className="mt-4">
                    {error.general && <Alert variant="danger">{error.general}</Alert>}

                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="formFullName">
                                <Form.Label className='text-secondary'>Full Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    required
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.fullName}
                                />
                                <Form.Control.Feedback type="invalid">{error.fullName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="formEmail">
                                <Form.Label className='text-secondary'>Email*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="email"
                                    required
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.email}
                                />
                                <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group className="mb-2" controlId="formPassword">
                                <Form.Label className='text-secondary'>Password*</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        size='lg'
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        isInvalid={!!error.password}
                                    />
                                    <span className="position-absolute" style={{ right: '10px', top: '10px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                    </span>
                                    <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={6}>
                            <Form.Group className="mb-2" controlId="formConfirmPassword">
                                <Form.Label className='text-secondary'>Confirm Password*</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        size='lg'
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleInputChange}
                                        isInvalid={!!error.confirmPassword}
                                    />
                                    <span className="position-absolute" style={{ right: '10px', top: '10px', cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                    </span>
                                    <Form.Control.Feedback type="invalid">{error.confirmPassword}</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>

                <div className="d-flex justify-content-between mt-4">
                    <Button
                        className="px-4 py-2 submit-btn-hover"
                        style={{
                            borderRadius: '30px',
                            background: 'linear-gradient(90deg, #007bff 0%, #004085 100%)',
                            borderColor: '#007bff'
                        }}
                        onClick={handleCancel}
                    >
                        CANCEL
                    </Button>
                    <Button
                        className="px-4 py-2 submit-btn-hover"
                        style={{
                            borderRadius: '30px',
                            color: "var(--color1)",
                            background: 'var(--color4)',
                            borderColor: 'var(--color4)'
                        }}
                        disabled={loading}
                        onClick={handleFormSubmit}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'CREATE'}
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default AddMember;

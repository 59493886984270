import React, { createContext, useContext, useState, useEffect } from "react";

const CategoryContext = createContext();

export const useCategory = () => {
  return useContext(CategoryContext);
};

export const CategoryProvider = ({ children }) => {
  const [category, setCategory] = useState(() => {
    const savedCategory = localStorage.getItem("category");
    return savedCategory ? JSON.parse(savedCategory) : null;
  });

  const updateCategory = (newCategory) => {
    setCategory(newCategory);
    localStorage.setItem("category", JSON.stringify(newCategory));
  };

  useEffect(() => {
    const savedCategory = localStorage.getItem("category");
    if (savedCategory) {
      setCategory(JSON.parse(savedCategory));
    }
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        category,
        updateCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
